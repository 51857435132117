<template>
  <v-container>
    <v-row class="mt-8">
      <v-col align="center">
        <p class="text-h3">
          Services
        </p>
        <p class="text-h5">
          We specialize in new construction as well as remodeling. We are here to enhance the beauty of your home.
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="6" lg="4" v-for="service in services" :key="service.name" align="center">
        <v-card light>
          <v-card-title class="justify-center">
            {{ service.name }}
          </v-card-title>
          <v-card-text>
            <v-img :src="getAssetImg(service.img)" width="330" height="300"></v-img>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

export default {
  components: {},
  name: "ServicesView",
  metaInfo: {
    title: 'Services',
  },
  data() {
    return {
      services: [
        {
          name: "Metal Framing",
          img: '/services/metalframe_qsloj9.webp'
        },
        {
          name: "Drywall Installation",
          img: '/services/drywall_acj5hb.webp'
        },
        {
          name: "Taping",
          img: '/services/taping_wru2hs.webp'
        },
        {
          name: "Smooth Finish",
          img: '/services/smooth-finish_higkk4.webp'
        },
        {
          name: "Orange Peel Finish",
          img: '/services/orange_ntsuop.webp'
        },
        {
          name: "Skim Coat",
          img: '/services/skim_jtwrry.webp'
        },
        {
          name: "Various Types of Textures",
          img: '/services/texture_g45bq7.webp'
        },
        {
          name: "Popcorn Ceiling Removal",
          img: '/services/popcorn-ceiling_grzt2k.webp'
        },
        {
          name: "Patchings",
          img: '/services/patching_dhmhoz.webp'
        },
      ]
    }
  },
};
</script>

<style scoped>
</style>
